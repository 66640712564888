import { Avatar, Card, Drawer, Menu, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { UserSwitchOutlined, UserDeleteOutlined } from "@ant-design/icons";
import Sider from "antd/es/layout/Sider";
import { MenuItems, SidebarProps } from "../interface/MainLayout.interface";
import "../index.css";
import {
  CircleIcon,
  HomeIcon,
  NotificationIcon,
  SettingIcon,
  UserIcon,
  CuriculumIcon,
} from "assets";
import logo from "assets/images/Logo.png";
import sloganlogo from "assets/images/sloganlogo.png";
import { tokens } from "utils/Constants";
import { BankNotesIcon } from "assets/icons/BankNotesIcon";
import { CalenderIcon } from "assets/icons/calenderIcon";
import {
  generateLetterImageURL,
  getInfo,
  getUserDeviceResolution,
  getUserInfo,
  isOnMobile,
} from "utils/Helpers";
import { useEffect, useState } from "react";
import { ATitle } from "components";
import ACard from "components/Card";
import { SurveyIcon } from "assets/icons/SurveyIcon";
import { AttendanceIcon } from "assets/icons/AttendanceIcon";

interface AMenuItem extends MenuItems {
  type?: "group";
}

type KeyLabelObject = {
  [key: string]: React.ReactNode;
};
var keyLabelObject: KeyLabelObject = {};
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  href?: String,
  children?: AMenuItem[],
  type?: "group"
): AMenuItem {
  keyLabelObject[key] = label;
  return {
    key,
    icon,
    href,
    children,
    label,
    type,
  } as AMenuItem;
}
const items: AMenuItem[] = [
  getItem("Dashboards", "1", <HomeIcon style={{ width: "12pt" }} />, "/"),

  getItem(
    "Manage Class",
    "2",
    <UserIcon style={{ width: "12pt" }} />,
    "/user",
    [
      getItem(
        "Enrollment Request",
        "3",
        <CircleIcon style={{ width: "12pt" }} />,
        "/evaluation-test-requests"
      ),
      getItem("Batch", "4", <CircleIcon style={{ width: "12pt" }} />, "/batch"),
      getItem(
        "Enrolled Students",
        "5",
        <CircleIcon style={{ width: "12pt" }} />,
        "/enrolledStudents"
      ),
      getItem(
        "Waiting List",
        "6",
        <CircleIcon style={{ width: "12pt" }} />,
        "/waitinglist"
      ),
    ]
  ),
  getItem(
    "Parent & Child Association",
    "55",
    <UserSwitchOutlined style={{ width: "12pt" }} />,
    "/parentAndChildAssociation"
  ),
  getItem(
    "Class Participation",
    "18",
    <UserSwitchOutlined style={{ width: "12pt" }} />,
    "/attendance"
  ),
  getItem(
    "Leave Request",
    "12",
    <CalenderIcon style={{ width: "12pt" }} />,
    "/leaveApproval"
  ),
  getItem(
    "Remove Request",
    "31",
    <UserDeleteOutlined style={{ width: "12pt" }} />,
    "/removeChildApproval"
  ),

  getItem(
    "Manage Curriculum",
    "7",
    <CuriculumIcon style={{ width: "12pt" }} />,
    "/course",
    [
      getItem(
        "Full Time Course",
        "8",
        <CircleIcon style={{ width: "12pt" }} />,
        "/course"
      ),
      getItem(
        "Part Time Course",
        "54",
        <CircleIcon style={{ width: "12pt" }} />,
        "/part-time-course"
      ),
    ]
  ),
  // getItem(
  //   "Communication",
  //   "10",
  //   <CommunicationIcon style={{ width: "12pt" }} />,
  //   "/assignments",
  //   [
  //     getItem(
  //       "Assignments",
  //       "11",
  //       <CircleIcon style={{ width: "12pt" }} />,
  //       "/assignments"
  //     ),
  //     getItem("Chat", "12", <CircleIcon style={{ width: "12pt" }} />, "/chat"),
  //     getItem(
  //       "News & Events",
  //       "13",
  //       <CircleIcon style={{ width: "12pt" }} />,
  //       "/events"
  //     ),
  //   ]
  // ),

  getItem(
    "User Management",
    "19",
    <NotificationIcon style={{ width: "12pt" }} />,
    "/events",
    [
    //   getItem(
    //     "Super Admin",
    //     "25",
    //     <CircleIcon style={{ width: "12pt" }} />,
    //     "/superAdmin"
    //   ),
      getItem(
        "Instructor",
        "20",
        <CircleIcon style={{ width: "12pt" }} />,
        "/instructor"
      ),
      // getItem(
      //   "Parent",
      //   "21",
      //   <CircleIcon style={{ width: "12pt" }} />,
      //   "/parent"
      // ),
      getItem(
        "Branch Admin",
        "100",
        <CircleIcon style={{ width: "12pt" }} />,
        "/branchAdmin"
      ),
      getItem(
        "Branch Associate",
        "28",
        <CircleIcon style={{ width: "12pt" }} />,
        "/branchAssociate"
      ),
      getItem(
        "Branch Self Service",
        "29",
        <CircleIcon style={{ width: "12pt" }} />,
        "/branchSelfService"
      ),
      getItem(
        "Customer Support User",
        "52",
        <CircleIcon style={{ width: "12pt" }} />,
        "/customerSupportUser"
      ),
    //   getItem(
    //     "Customer Support Admin",
    //     "52",
    //     <CircleIcon style={{ width: "12pt" }} />,
    //     "/customerSupportAdmin"
    //   ),
      // getItem(
      //   "Students",
      //   "21",
      //   <CircleIcon style={{ width: "12pt" }} />,
      //   "/students"
      // ),
    ]
  ),

  getItem(
    "Timesheet Report",
    "50",
    <AttendanceIcon style={{ width: "12pt" }} />,
    "/timesheetreport",
    [
      getItem(
        "Employee",
        "51",
        <CircleIcon style={{ width: "12pt" }} />,
        "/timesheetreport/employee"
      ),
      // getItem(
      //   "Students",
      //   "52",
      //   <CircleIcon style={{ width: "12pt" }} />,
      //   "/timesheetreport/students"
      // ),
    ]
  ),
  getItem(
    "Refunds",
    "58",
    <BankNotesIcon style={{ width: "12pt" }} />,
    "/refunds"
  ),
  getItem(
    "Credit & Discounts",
    "29",
    <BankNotesIcon style={{ width: "12pt" }} />,
    "/creditanddiscounts"
  ),
  getItem(
    "Billing & Payments",
    "22",
    <BankNotesIcon style={{ width: "12pt" }} />,
    "/bilingandpayments"
  ),
  getItem(
    "Communication",
    "10",
    <CuriculumIcon style={{ width: "12pt" }} />,
    "/chat",
    [
      getItem("Chat", "24", <CircleIcon style={{ width: "12pt" }} />, "/chat"),
      getItem(
        "News & Events",
        "25",
        <CircleIcon style={{ width: "12pt" }} />,
        "/newsAndEvents"
      ),
      getItem(
        "Coupons",
        "30",
        <CircleIcon style={{ width: "12pt" }} />, 
        "/coupons"
      ),
    ]
  ),
  getItem(
    "Settings",
    "14",
    <SettingIcon style={{ width: "12pt" }} />,
    "/settings",
    [
      getItem(
        "Class Time",
        "15",
        <CircleIcon style={{ width: "12pt" }} />,
        "/timeSlot"
      ),
      getItem(
        "Branch",
        "16",
        <CircleIcon style={{ width: "12pt" }} />,
        "/branch"
      ),
      getItem("Logs", "17", <CircleIcon style={{ width: "12pt" }} />, "/logs"),
    ]
  ),
  getItem(
    "Survey",
    "101",
    <SurveyIcon style={{ width: "12pt" }} />,
    "/survey"
  ),
];

export const Sidebar: React.FC<SidebarProps> = (props) => {
  const { open, onClose, onMobile } = props;
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    const userInfo = getInfo();
    setUser(userInfo);
  }, []);

  const onClick = ({ key, item }: any) => {
    const label = keyLabelObject[key];
    navigate(item.props.href, { state: { label: label } });
    onMobile && onClose(false);
  };

  // const token: string | null = localStorage.getItem("token");
  // const decodedToken: JWTToken = jwt_decode(token || "");

  // const isAuthorized = (permission: string): boolean => {
  //   return true;
  //   // return permissionData(decodedToken).some(element => {
  //   // 	return permission === element;
  //   // });
  // };

  const content = onMobile ? (
    <Drawer
      placement="left"
      open={open}
      closable={true}
      width={getUserDeviceResolution().width - 80}
      onClose={() => onClose(false)}
      style={{
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0",
        }}
      >
        <img alt="logo" src={sloganlogo} width={"80px"} style={{
          marginTop: "-20px",
          marginBottom: "-1rem",
          width: "18rem"
          }} />
      </div>
      {/* <Card
        style={{
          margin: "1rem 1rem",
        }}
      >
        <Space
          size={"large"}
          style={{ width: "100%" }}
          align="center"
          direction="vertical"
        >
          <Avatar
            size={46}
            icon={
              <img
                src={generateLetterImageURL(user?.first_name, user?.last_name)}
                alt="image"
              />
            }
          />
          <ATitle
            level={5}
            content={`${user?.first_name} ${user?.last_name}`}
          />
        </Space>
      </Card> */}
      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
        style={{
          overflowX: "hidden",
          width: "max-content !important",
          border: "none",
        }}
      />
    </Drawer>
  ) : (
    <Sider
      trigger={null}
      collapsible
      collapsed={open}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        zIndex: "5",
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: tokens.BG_LIGHT,
        borderRight: tokens.COLOR_LIGHTBLACK_L50 + " 1px solid",
        width: "max-content",
      }}
    >
      <div
        style={{
          padding: "1.5rem 0.5rem",
        }}
      >
        <img alt="logo" src={sloganlogo} width={"80px"}
          style={{
            marginBottom: "-1rem",
            width: "14rem"
          }}
        />
      </div>
      {/* <Card
        style={{
          margin: "1rem 1rem",
        }}
      >
        <Space
          size={"large"}
          style={{ width: "100%" }}
          align="center"
          direction="vertical"
        >
          <Avatar
            size={46}
            icon={
              <img
                src={generateLetterImageURL(user?.first_name, user?.last_name)}
                alt="image"
              />
            }
          />
          <ATitle
            level={5}
            content={`${user?.first_name} ${user?.last_name}`}
          />
        </Space>
      </Card> */}
      <Menu
        onClick={onClick}
        mode="inline"
        defaultSelectedKeys={["1"]}
        items={items}
        style={{
          width: "max-content !important",
          paddingBottom: "30px",
        }}
      />
    </Sider>
  );
  return <>{content}</>;
};

export default Sidebar;

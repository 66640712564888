import { Assignment } from "../interface";

const actions = {
  FETCH_CHILD_BYINSTRUCTOR: "FETCH_CHILD_BYINSTRUCTOR",
  FETCH_CHILD_BYINSTRUCTOR_SUCCESS: "FETCH_CHILD_BYINSTRUCTOR_SUCCESS",
  SAVE_ASSIGNMENT: "SAVE_ASSIGNMENT",
  SAVE_ASSIGNMENT_SUCCESS: "SAVE_ASSIGNMENT_SUCCESS",
  FETCH_ASSIGNMENTS: " FETCH_ASSIGNMENTS",
  FETCH_ASSIGNMENTS_SUCCESS: "FETCH_ASSIGNMENTS_SUCCESS",
  SUBMIT_ASSIGNMENT: "SUBMIT_ASSIGNMENT",
  SUBMIT_ASSIGNMENT_SUCCESS: "SUBMIT_ASSIGNMENT_SUCCESS",

  fetchCoursesByInstructor: (
    page: number,
    pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_CHILD_BYINSTRUCTOR,
    page,
    pageSize,
    resolve,
    reject,
  }),

  fetchAssignments: (
    page: number,
    pageSize: number,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_ASSIGNMENTS,
    page,
    pageSize,
    resolve,
    reject,
  }),

  saveAssignment: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SAVE_ASSIGNMENT,
    data,
    resolve,
    reject,
  }),

  submitAssignment: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.SUBMIT_ASSIGNMENT,
    data,
    resolve,
    reject,
  }),
};

export default actions;

import { message } from "antd";

const actions = {
  FETCH_STUDENTS: "FETCH_STUDENTS",
  FETCH_STUDENTS_SUCCESS: "FETCH_STUDENTS_SUCCESS",
  FETCH_STUDENTS_FAILED: "FETCH_STUDENTS_FAILED",
  FETCH_STUDENT: "FETCH_STUDENT",
  FETCH_STUDENT_SUCCESS: "FETCH_STUDENT_SUCCESS",
  FETCH_STUDENT_FAILED: "FETCH_STUDENT_FAILED",
  ADD_PROGRESS_REPORT: "ADD_PROGRESS_REPORT",
  ADD_PROGRESS_REPORT_SUCCESS: "ADD_PROGRESS_REPORT_SUCCESS",
  ADD_PROGRESS_REPORT_FAILED: "ADD_PROGRESS_REPORT_FAILED",
  FETCH_PROGRESS_REPORTS: "FETCH_PROGRESS_REPORTS",
  FETCH_PROGRESS_REPORTS_SUCCESS: "FETCH_PROGRESS_REPORTS_SUCCESS",
  FETCH_PROGRESS_REPORTS_FAILED: "FETCH_PROGRESS_REPORTS_FAILED",
  FETCH_PROGRESS_REPORT: "FETCH_PROGRESS_REPORT",
  FETCH_PROGRESS_REPORT_SUCCESS: "FETCH_PROGRESS_REPORT_SUCCESS",
  FETCH_PROGRESS_REPORT_FAILED: "FETCH_PROGRESS_REPORT_FAILED",
  UPDATE_PROGRESS_REPORT: "UPDATE_PROGRESS_REPORT",
  UPDATE_PROGRESS_REPORT_SUCCESS: "UPDATE_PROGRESS_REPORT_SUCCESS",
  UPDATE_PROGRESS_REPORT_FAILED: "UPDATE_PROGRESS_REPORT_FAILED",
  FETCH_ALL_PROGRESS_REPORTS: "FETCH_ALL_PROGRESS_REPORTS",
  FETCH_ALL_PROGRESS_REPORTS_SUCCESS: "FETCH_ALL_PROGRESS_REPORTS_SUCCESS",
  FETCH_ALL_PROGRESS_REPORTS_FAILED: "FETCH_ALL_PROGRESS_REPORTS_FAILED",
  FETCH_MILESTONE: "FETCH_MILESTONE",
  FETCH_MILESTONE_SUCCESS: "FETCH_MILESTONE_SUCCESS",
  UPDATE_MILESTONE: "UPDATE_MILESTONE",
  UPDATE_MILESTONE_SUCESS: "UPDATE_MILESTONE_SUCESS",

  addProgressReport: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.ADD_PROGRESS_REPORT,
    data,
    resolve,
    reject,
  }),

  fetchStudents: (
    page: number,
    pageSize: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_STUDENTS,
    page,
    pageSize,
    resolve,
    reject,
  }),

  fetchStudent: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_STUDENT,
    resolve,
    reject,
  }),

  fetchAllProgressReports: (
    resolve: (token: string) => void,
    reject: (value: string) => void,
    batch_id?: any
  ) => ({
    type: actions.FETCH_ALL_PROGRESS_REPORTS,
    resolve,
    reject,
    batch_id,
  }),

  fetchProgressReports: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_PROGRESS_REPORTS,
    resolve,
    reject,
  }),

  fetchProgressReport: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_PROGRESS_REPORT,
    resolve,
    reject,
  }),
  fetchMilestone: (
    id: number,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    id,
    type: actions.FETCH_MILESTONE,
    resolve,
    reject,
  }),
  updateMilestone: (
    data: Object,
    resolve: (data: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.UPDATE_MILESTONE,
    data,
    resolve,
    reject,
  }),
};

export default actions;

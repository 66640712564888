import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

interface BatchReturnTypes {
  [x: string]: string;
}

const createBatch = takeLatest(
  actions.CREATE_BATCH,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.post, `api/batch`, action.data, null);
      yield put({
        type: actions.CREATE_BATCH_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.CREATE_BATCH_FAILED });
      yield call(action.reject, error.response);
    }
  }
);

const fetchBatch = takeLatest(
  actions.FETCH_BATCH,
  function* (action: AnyAction): Generator<Effect, void, BatchReturnTypes> {
    try {
      const response = yield call(api.get, "api/batch/" + action.id, false);
      yield put({
        type: actions.FETCH_BATCH_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.FETCH_BATCH_FAILED });
      yield call(action.reject, error.response);
    }
  }
);

const fetchBranches = takeLatest(
  actions.FETCH_BATCHES,
  function* (action: AnyAction): Generator<Effect, void, BatchReturnTypes> {
    try {
      const response = yield call(
        api.get,
        urlGenerator("api/batch", action),
        false
      );
      yield put({
        type: actions.FETCH_BATCHES_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.FETCH_BATCHES_FAILED });
      yield call(action.reject, error.response);
    }
  }
);

const fetchBatchesByInstructor = takeLatest(
  actions.FETCH_BATCHES_BY_INSTRUCTOR,
  function* (action: AnyAction): Generator<Effect, void, BatchReturnTypes> {
    try {
      const response = yield call(
        api.get,
        "api/batch/batchByInstructor",
        false
      );
      yield put({
        type: actions.FETCH_BATCHES_BY_INSTRUCTOR_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.FETCH_BATCHES_BY_INSTRUCTOR_FAILED });
      yield call(action.reject, error.response);
    }
  }
);

const updateBranch = takeLatest(
  actions.UPDATE_BATCH,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/batch/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_BATCH_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.UPDATE_BATCH_FAILED });
      yield call(action.reject, error.response);
    }
  }
);

const deleteBranch = takeLatest(
  actions.DELETE_BATCH,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.delete, `api/batch/${action.id}`);
      yield put({
        type: actions.DELETE_BATCH_SUCCESS,
        payload: action.id,
      });
      yield call(action.resolve, response);
    } catch (error: any) {
      yield put({ type: actions.DELETE_BATCH_FAILED });
      yield call(action.reject, error.response);
    }
  }
);

const totalStudentOfBatch = takeLatest(
  actions.FETCH_TOTAL_STUDENTS_OF_BATCH,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const { courseId, branchId, timeSlotId, daysPerWeek, daysParam, resolve, reject } = action;
      
      const daysArray = daysParam ? (Array.isArray(daysParam) ? daysParam : [daysParam]) : [];

      const daysParams = daysArray.join(',');

      const response = yield call(api.get, `api/batch/getTotalStudent/${courseId}/${branchId}/${timeSlotId}/${daysPerWeek}?available_days=${daysParams}`);
      
      yield put({
        type: actions.FETCH_TOTAL_STUDENTS_OF_BATCH_SUCCESS,
        payload: response,
      });
      
      yield call(resolve, response);
    } catch (error: any) {
      yield put({ type: actions.FETCH_TOTAL_STUDENTS_OF_BATCH_FAILED });
      yield call(action.reject, error.message || "Failed to fetch total students");
    }
  }
);


export default function* saga() {
  yield all([
    createBatch,
    fetchBranches,
    updateBranch,
    deleteBranch,
    fetchBatch,
    fetchBatchesByInstructor,
    totalStudentOfBatch
  ]);
}

const actions = {
  FETCH_KIDSINFO: "FETCH_KIDSINFO",
  FETCH_KIDSINFO_SUCCESS: "FETCH_KIDSINFO_SUCCESS",

  fetchKidsInfo: (
    resolve: (token: string) => void,
    reject: (value: string) => void
  ) => ({
    type: actions.FETCH_KIDSINFO,
    resolve,
    reject,
  }),
};

export default actions;

const actions = {
    FETCH_EMPLOYEE_DETAILS: "FETCH_EMPLOYEE_DETAILS",
    FETCH_EMPLOYEE_DETAILS_SUCCESS: "FETCH_EMPLOYEE_DETAILS_SUCCESS",
    FETCH_EMPLOYEE_DETAILS_FAILED: "FETCH_EMPLOYEE_DETAILS_FAILED",
    FETCH_ALL_ATTENDANCE_OF_EMPLOYEE: "FETCH_ALL_ATTENDANCE_OF_EMPLOYEE",
    FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_SUCCESS: "FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_SUCCESS",
    FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_FAILED: "FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_FAILED",
    FETCH_EMPLOYEE_ROLE: "FETCH_EMPLOYEE_ROLE",
    FETCH_EMPLOYEE_ROLE_SUCCESS: "FETCH_EMPLOYEE_ROLE_SUCCESS",
    FETCH_EMPLOYEE_ROLE_FAILED: "FETCH_EMPLOYEE_ROLE_FAILED",

    fetchEmployeeDetails: (
        // page: number,
        // pageSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_EMPLOYEE_DETAILS,
        // page,
        // pageSize,
        resolve,
        reject
    }),

    fetchAllAttendanceOfEmployee: (
        page: number,
        pageSize: number,
        id: any,
        filter: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_ALL_ATTENDANCE_OF_EMPLOYEE,
        page,
        pageSize,
        id,
        filter,
        resolve,   
        reject,
    }),

    fetchEmployeeRole: (
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_EMPLOYEE_ROLE,
        resolve,
        reject
    }),
}

export default actions;